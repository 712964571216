@use '/src/utilities/variables' as var;

.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var.$navy;
    background-color: var.$navy;
    width: 100%;
    height: 68px;
    text-decoration: none;
    z-index: 2;
}

.navbar-list {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: auto;
    padding: 0;
}

.navbar-item {
    margin: 0 120px;
    color: var.$light-pink;
    text-decoration: none;
    text-align: center;
    font-family: Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.navbar a {
    color: var.$light-pink;
    text-decoration: none;
}

@media (max-width: 768px) {
    .navbar {
        justify-content: space-between;
    }

    .navbar-items {
        position: absolute;
        top: 68px;
        left: 0;
        width: 100%;
        flex-direction: column;
        background-color: var.$navy;
        display: none;
        text-align: center;
    }

    .navbar-items.active {
        display: flex;
    }

    .navbar-item {
        margin: 0px 5px;
        font-size: 18px;
    }
}

