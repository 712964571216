@use '/src/utilities/variables' as var;

.background-image-1 {
    position: absolute;
    right: 0;
    z-index: 1;

    img {
        width: 100%;
        height: 100%;
    }
}

.background-image-2 {
    position: absolute;
    left: 0;
    top: 600px;
    z-index: 1;

    img {
        width: 100%;
        height: 100%;
    }
}

.background-image-3 {
    position: absolute;
    right: 0;
    top: 1550px;
    z-index: 1;

    img {
        width: 100%;
        height: 100%;
    }
}

.home {
    position: relative;
    vertical-align: top;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var.$light-navy;
    background: linear-gradient(180deg, var.$navy 0%, var.$eggshell 40%);
    height: 965px;
    text-decoration: none;
}

.home-container {
    justify-content: center;
    align-items: center;
    color: var.$light-pink;
    width: 790px;
    text-decoration: none;
    margin-top: -100px;

    h1 {
        color: var.$navy;
        text-align: center;
        font-family: Daydream;
        font-size: 64px;
        font-style: normal;
        font-weight: 0;
        line-height: normal;
        margin-top: -15px;
        margin-bottom: -10px;
    }

    h2 {
        color: var.$purple;
        text-align: center;
        font-family: Helvetica;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }

    p {
        color: var.$navy;
        text-align: center;
        font-family: Helvetica;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.about {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    color: var.$light-navy;
    background: linear-gradient(180deg, var.$eggshell 40%, var.$navy 100%);
    height: 965px;
    text-decoration: none;
    margin: auto;
}

.about-container-1 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 1132px;
    height: 662px;
    margin-top: 45px;
}

.about-container-2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 1132px;
    height: 160px;
    margin-bottom: 100px;
}

.about-box-1, .about-box-2, .about-box-3, .about-box-4, .about-box-5 {
    display: flex;
    margin: 10px 10px;
    background-color: var.$navy;
    border-radius: 10px;
    border: 1px solid var.$light-pink;
    z-index: 2;
}

.about-box-1 {
    background-color: var.$light-navy;
    width: 768px;
    height: 642px;
    flex-shrink: 0;
    justify-content: center;

    .about-box-text {
        display: flex;
        flex-direction: column;
        color: var.$navy;
        width: 661px;
        height: 338px;
        text-decoration: none;
        margin-top: 43px;
    
    
        h2 {
            color: var.$light-pink;
            text-align: center;
            font-family: Daydream;
            font-size: 36px;
            font-style: normal;
            font-weight: 0;
            line-height: normal;
    
        }
    
        p {
            color: var.$pale-pink;
            font-family: Helvetica;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            z-index: 3; // image of me is z-index 1 and 2, added this so links are on top
        };
    
        a {
            color: var.$light-pink;
            font-weight: 600;
        }
    }

    .me-box-halftone, .me-box-normal {
        width: 591px;
        height: 393px;
        position: absolute;
        margin-left: 177px;
        margin-top: 249px;
    
        img {
            width: 591px;
            height: 393px;
        }
    }
    
    .me-box-halftone {
        z-index: 2;
    }
    
    .me-box-normal {
        z-index: 1;
    }
    
}

.about-box-2 {
    display: flex;
    flex-direction: column;
    background-color: var.$light-pink;
    width: 295px;
    height: 202px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    h2 {
        color: var.$light-navy;
        text-align: center;
        font-family: Daydream;
        font-size: 36px;
        font-style: normal;
        font-weight: 0;
        line-height: normal;
        margin-bottom: -10px;
    }

    .links-container {
        display: flex;
        margin: auto;
        justify-content: space-evenly;
        align-items: center;
        width: 250px;

        a {
            text-decoration: none;
            color: var.$light-pink;
        }
    }
}

.about-box-3 {
    background-color: var.$eggshell;
    width: 295px;
    height: 412px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    p {
        color: var.$navy;
        font-family: Helvetica;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .about-box-3-text {
        display: flex;
        width: 214px;
        height: 227px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }

    a {
        color: var.$purple;
        font-weight: 600;
    }
}

.about-box-4 {
    background-color: var.$eggshell;
    width: 615px;
    height: 140px;
    flex-shrink: 0;

    img {
        margin: 15px 25px;
    }

    .about-box-4-text {
        margin: 10px 10px;
        display: flex;
        flex-direction: column;
        color: var.$navy;
        width: 615px;
        height: 140px;
        text-decoration: none;
        margin-top: 0px;
    
        h2 {
            color: var.$light-navy;
    
            font-family: Daydream;
            font-size: 24px;
            font-style: normal;
            font-weight: 0;
            line-height: normal;
        }
    
        p {
            margin-top: -10px;
            color: var.$navy;
            font-family: Helvetica;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        };
    
        a {
            color: var.$light-pink;
        }
    }
}

.about-box-5 {
    background-color: var.$navy;
    width: 459px;
    height: 140px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    .about-box-5-text {
        width: 389px;
        height: 69px;
        flex-shrink: 0;
    }

    p {
        color: var(--light-pink, #DBBFDD);
        font-family: Helvetica;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin:0;
    }
    a {
        color: var.$pale-pink;
        font-weight: 600;
    }


}

@media (max-width: 768px) {
    .background-image-1 {
        img {
            display: none;
        }
    }

    .background-image-2 {
        img {
            display: none;
        }
    }

    .background-image-3 {
        img {
            display: none;
        }
    }

    .home {
        flex-direction: column;
        height: auto;
        padding: 20px;
    }

    .home-container {
        width: 100%;
        margin-top: 100px;
        
        h1 {
            font-size: 48px;
        }

        h2 {
            font-size: 24px;
        }

        p {
            font-size: 18px;
        }
    }

    .about {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        height: auto;
        padding: 20px;
    }

    .about-container-1,
    .about-container-2 {
        margin-left: -55px;
        display: flex;
        width: 300px;
        height: auto;
        padding: 0;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .about-box-1 {
        width: 100%;
        height: auto;
        padding: 20px;
        
        .about-box-text {
            width: 100%;
            height: auto;
            margin-top: 20px;
            padding: 10px;
            
            h2 {
                font-size: 28px;
            }
            
            p {
                font-size: 14px;
            }
        }

        .me-box-halftone,
        .me-box-normal {
            width: 100%;
            height: auto;
            margin-left: 0;
            margin-top: 20px;
            
            img {
                width: 100%;
                height: auto;
                display: none;
            }
        }
    }

    .about-box-2,
    .about-box-3,
    .about-box-4,
    .about-box-5 {
        width: 100%;
        height: auto;
        padding: 20px;
        
        h2,
        p {
            font-size: 16px;
        }

        .links-container {
            width: 100%;
        }
    }

    .about-box-3 .about-box-3-text {
        width: 100%;
        height: auto;
        padding: 10px;
    }

    .about-box-4 .about-box-4-text {
        width: 100%;
        height: auto;
        padding: 10px;
    }

    .about-box-5 .about-box-5-text {
        width: 100%;
        height: auto;
        padding: 10px;
    }
    .about-box-2 {
        h2 {
            font-size: 24px;
            margin-bottom: 10px;
        }
    }
}
