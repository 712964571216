@use '/src/utilities/variables' as var;

.filler {
    width: 100%;
    height: 100vh;
    background: var.$eggshell;

    p {
        text-align: center;
        font-family: Helvetica;
        font-size: 2rem;
        color: var.$light-navy;
        margin-bottom: 30px;
        margin-top: -10px;
    }
}