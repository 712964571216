@use '/src/utilities/variables' as var;

.footer {
    position-anchor: bottom;
    position: absolute;
    width: 100%;
    height: 68px;
    flex-shrink: 0;
    background-color: var.$light-navy;
    z-index: 2;
}

.footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var.$light-pink;
    width: 100%;
    height: 100%;
    text-decoration: none;
    margin: auto;
    p {
        color: #DBBFDD;
        text-align: center;
        font-family: Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}