body {
    cursor: url('./cursors/StarNormal.cur'), auto;
}

a {
    cursor: url('./cursors/StarDiagonal.cur'), auto;
}
  
textarea {
    cursor: url('./cursors/StarText.cur'), auto;
}   

label {
    cursor: url('./cursors/StarText.cur'), auto;
} 

@font-face {
    font-family: 'Daydream';
    src: local('Daydream'), url('./fonts/Daydream.ttf') format('truetype');
  }

  