@use '/src/utilities/variables' as var;



.contact-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: var.$eggshell;
  padding: 20px;
  align-items: center;

  h1 {
    margin-top: 50px;
    text-align: center;
    font-size: 2.5rem;
    color: var.$navy;
    font-family: Daydream;
  }
  
  p {
    text-align: center;
    font-family: Helvetica;
    font-size: 1rem;
    color: var.$light-navy;
    margin-bottom: 30px;
    margin-top: -10px;
  }

  a {
    color: var.$purple;
    font-weight: 600;
  } 

  a:hover {
    color: var.$light-navy;
  }
}

form {
  background-color: var.$eggshell;
  border-radius: 10px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-width: 500px;
  width: 100%;
}

label {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  color: var.$navy;
  margin-bottom: 1px;
}

input,
textarea {
  margin-top: 5px;
  width: 97%;
  padding: 10px 10px;
  border: 1px solid var.$light-pink;
  border-radius: 5px;
  font-size: 1rem;
  margin-bottom: 20px;
  transition: border-color 0.3s ease;
  font-family: Helvetica;
}

input:focus,
textarea:focus {
  border-color: var.$light-navy;
  outline: none;
}

textarea {
  resize: none;
  min-height: 150px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: var.$purple;
  color: var.$eggshell;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var.$light-navy;
}

@media (max-width: 768px) {
  form {
    padding: 20px;
  }

  input,
  textarea {
    padding: 8px;
  }

  button {
    font-size: 1rem;
  }
}

.snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: var.$navy;
  color: var.$eggshell;
  text-align: center;
  border-radius: 5px;
  padding: 16px;
  position: fixed;
  z-index: 10;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  font-size: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: visibility 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}

.snackbar.success {
  background-color: var.$purple;
  visibility: visible;
  opacity: 1;
}

.snackbar.error {
  background-color: var.$light-navy;
  visibility: visible;
  opacity: 1;
}
